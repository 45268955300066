<template>
  <!-- Sidebar -->
  <reviewer-side-bar> </reviewer-side-bar>

  <!-- Sidebar -->
  <section class="home-section">
    <!-- Header -->
    <reviewer-nav-bar>
      <ol class="list-reset flex">
        <li>
          <router-link to="/admin/review"
            ><span class="text-grey-300 ">Home</span></router-link
          >
        </li>
        <li><span class="text-gray-500 mx-2">/</span></li>
        <li>
          <a href="#" class="pointer-events-none  text-primary-600"
            >Professions Report</a
          >
        </li>
      </ol>
    </reviewer-nav-bar>
    <!-- Header -->

    <!-- Main Content -->
    <div class="home-content">
      <div class="container mx-auto px-4 sm:px-8">
        <div class="relative py-4 mb-4">
          <h2 class="absolute mb-4 text-2xl font-semibold">
            View profession reports for all applications
          </h2>

          <p class="absolute right-0">
            <button
              class="px-6 text-white bg-primary-600 hover:text-primary-600 font-medium text-xs leading-tight uppercase rounded focus: focus:outline-none focus:ring-0 transition duration-150 mt-0 ease-in-out text-right"
              @click="exportTable()"
            >
              <i class="fa fa-file-excel text-xl"></i>
              Generate Report
            </button>
          </p>
        </div>
        <div class="mt-20 border p-2 shadow-md flex gap-8">
          <div>
            <label for="" class="">License Status</label>
            <select
                class="form-select appearance-none block w-full px-6 py-2 text-base font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                v-model="searchTermStatus"
                aria-label="Default select example"
                @change="applyFilter()"
            >
              <option value="">All</option>
              <option
                  v-for="appStatus in applicationStatuses"
                  :key="appStatus.id"
                  :value="appStatus"
              >
                {{ appStatus.name }}
              </option>
            </select>
          </div>
          <div v-if="expertLevel!='REG'">
            <label for="" class="ml-4">Region</label>
            <select
                class="form-select appearance-none block w-full px-6 ml-4 py-2 text-base font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                v-model="searchTermRegion"
                aria-label="Default select example"
                @change="applyFilter()"
            >
              <option value="">All</option>
              <option value="0">Federal</option>
              <option
                  v-for="region in regions"
                  :value="region.id"
                  :key="region.id"
              >
                {{ region.name }}
              </option>
            </select>
          </div>
          <div>
            <label for="" class="ml-4"></label>
            <input
                type="search"
                class="form-control relative flex-auto min-w-0 block w-full px-6 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                placeholder="Search by profession"
                aria-label="Search"
                aria-describedby="button-addon2"
                v-model="searchTermProf"
                @keyup.enter="applyFilter()"
            />
          </div>
        </div>
        <div class="vld-parent mt-2">
          <vue-table-lite
              :is-loading="userTable.isLoading"
              :columns="userTable.columns"
              :rows="userTable.rows"
              :total="userTable.totalRecordCount"
              :sortable="userTable.sortable"
              @do-search="doSearch"
          ></vue-table-lite>
        </div>
      </div>
    </div>
    <!-- Main Content -->
  </section>
</template>

<script>

import { reactive, ref } from "@vue/reactivity";
import { onMounted } from "@vue/runtime-core";
import { useStore } from "vuex";
import ReviewerNavBar from "./SharedComponents/navBar.vue";
import ReviewerSideBar from "./SharedComponents/sideNav.vue";
import * as XLSX from "xlsx";
import VueTableLite from "vue3-table-lite";

import Loading from "vue3-loading-overlay";
export default {
  components: {
    VueTableLite,
    ReviewerNavBar,
    ReviewerSideBar,
  },

  setup() {
    const store = useStore();
    let allData = ref([]);
    let isLoading = ref(true);
    let searchTermStatus = ref("");
    let searchTermRegion = ref("");
    let searchTermProf = ref("");
    let totalValues = ref({ newLicense: 0, renewal: 0, goodStanding: 0 });
    let applicationStatuses = ref([]);
    let expertLevel = JSON.parse(localStorage.getItem("allAdminData"))
      ? JSON.parse(localStorage.getItem("allAdminData")).expertLevel.code
      : {};

    const userTable = ref({ isLoading: true });
    let tableData = reactive([]);
    let regions = ref([]);
    const searchTerm = [];

    const fetchProfessionReport = apiParameters => {
      isLoading.value = true;

      store
          .dispatch("stats/getLicensesCountByProfession", apiParameters)
          .then(res => {
            tableData = [];

            res.data.rows.forEach(element => {
              tableData.push({
                professionName: element.professionName ? element.professionName : "",
                goodStanding: element.goodStanding ? +element.goodStanding : 0,
                newLicense: element.newLicense ? +element.newLicense : 0,
                renewal: element.renewal ? +element.renewal : 0,
                lostLicense: element.lostLicense ? +element.lostLicense : 0,
              });
            });

            allData.value = tableData;
            userTable.value = {
              isLoading: false,
              columns: [
                {
                  label: "Profession Name",
                  field: "professionName",
                  width: "65%",
                  sortable: true,
                  isKey: true
                },
                {
                  label: "Good Standing",
                  field: "goodStanding",
                  width: "15%",
                  sortable: true
                },
                {
                  label: "New License",
                  field: "newLicense",
                  width: "15%",
                  sortable: true
                },
                {
                  label: "Renewal",
                  field: "renewal",
                  width: "15%",
                  sortable: true
                },
                {
                  label: "Lost License",
                  field: "lostLicense",
                  width: "15%",
                  sortable: true
                }
              ],
              rows: tableData,
              totalRecordCount: res.data.count,
              sortable: {
                order: "professionName",
                sort: "asc"
              }
            };
          });
    };
    const fetchRegion = () => {
      store.dispatch("report/getRegions").then((res) => {
        regions.value = res.data.data;
      });
    };

    const exportTable = () => {
        var wb = XLSX.utils.book_new();
        wb.Props = {
          Title: "Professions report",
          Subject: "Professions Report",
          Author: "MOH",
          CreatedDate: new Date(),
        };
        wb.SheetNames.push("Professions Report");
        const ws = XLSX.utils.json_to_sheet(tableData);
        wb.Sheets["Professions Report"] = ws;
        XLSX.writeFile(wb, new Date().toISOString().slice(0, 10) + "_professions.xlsx");
    };
    const doSearch = (offset, limit, order, sort) => {
      userTable.value.isLoading = true;

      setTimeout(() => {
        userTable.value.isReSearch = offset == undefined ? true : false;
        offset = offset / 10;

        fetchProfessionReport([
          offset,
          limit,
          { key: "applicationStatus", value: searchTermStatus.value ? searchTermStatus.value.id : null,},
          { key: "region", value: searchTermRegion.value ? searchTermRegion.value : null,},
          { key: "professionName", value: searchTermProf.value ? searchTermProf.value : null,},
        ]);
        userTable.value.sortable.sort = sort;
      }, 600);
    };

    const applyFilter = () => {
      userTable.value.isLoading = true;
      fetchProfessionReport([0, 10,
        { key: "applicationStatus", value: searchTermStatus.value ? searchTermStatus.value.id : null,},
        { key: "region", value: searchTermRegion.value ? searchTermRegion.value : null,},
        { key: "professionName", value: searchTermProf.value ? searchTermProf.value : null,},
      ]);
    };

    onMounted(() => {

      fetchRegion();
      fetchProfessionReport([0, 10]);

      let tempAp = JSON.parse(localStorage.getItem("applicationStatuses"))
          ? JSON.parse(localStorage.getItem("applicationStatuses"))
          : [];

      applicationStatuses.value = tempAp.filter((application) => {
        return (
            application.code == "APP" ||
            application.code == "DEC" ||
            application.code == "SUSP" ||
            application.code == "RTN" ||
            application.code == "USUP" ||
            application.code == "RVK"
        );
      });
    });

    return {
      doSearch,
      exportTable,
      isLoading,
      allData,
      expertLevel,
      searchTermStatus,
      searchTermRegion,
      searchTermProf,
      applyFilter,
      applicationStatuses,
      totalValues,
      userTable,
      regions,
      searchTerm,
    };
  },
};
</script>
